

/* Page Header */
.m-compare .b-pageHeader__search h3{
    padding: 0px 15px;
}

/* Info Bar */
.m-compare .b-infoBar h5{
    font: 600 14px 'Open Sans',sans-serif;
    margin: 5px 0 0 0;
}

.m-compare .b-infoBar h5 span{
    font: 700 16px 'Open Sans',sans-serif;
}

.m-compare .b-infoBar {
    padding: 30px 0 25px 0;
}

/* Compare */
.b-compare{
    padding: 65px 0 155px 0;
}

.b-compare__images{
    margin-bottom: 45px;
}

.b-compare__images-item{
    padding-bottom: 30px;
}

.b-compare__images-item h3{
    font: 700 16px 'Open Sans',sans-serif;
    text-align: center;
    margin: 0 0 25px 0;
    text-transform: uppercase;
}

.b-compare__images-item-price{
    text-align: center;
    padding: 8px 0;
    font: 700 20px 'PT Sans',sans-serif;
    margin-top: 20px;
    height:38px;
    position: relative;
}

.b-compare__images-item-price-vs{
    width:50px;
    height:50px;
    position: absolute;
    border-radius: 100%;
    border:10px solid #fff;
    font: 600 13px 'Open Sans',sans-serif;
    padding-top: 5px;
    left:100%;
    margin-left: -8px;
    top:50%;
    margin-top: -25px;
    z-index: 10000;
}

.b-compare__block{
    margin-bottom: 40px;
}

.b-compare__block-title{
    padding: 13px 78px 13px 15px;
}

.b-compare__block h3{
    margin: 0;
}

.b-compare__block-title{
     position: relative;
     height:46px;
}

.b-compare__block-title > a{
    color:#fff;
    position: absolute;
    right:0;
    top:0;
    display: inline-block;
    border-bottom: 46px solid #f76d2b;
	border-left: 28px solid transparent;
	height: 0;
	width: 80px;
}

.b-compare__block-title > a span.fa{
    margin: 15px 0 0 25px;
}

.b-compare__block-title.m-active > a{
    border-bottom-color:#555;
}

.b-compare__block-inside{
    margin-top: 35px;
    display: none;
}

.j-inside.m-active{
    display: block;
}

.b-compare__block-inside-title{
    text-align: right;
    font: 600 13px 'Open Sans',sans-serif;
    padding-right: 45px;
    padding-bottom: 10px;
}

.b-compare__block-inside-value{
    font: 300 13px 'Open Sans',sans-serif;
    text-align: left;
    padding-left: 45px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.b-compare__block-inside > .row{
    margin-bottom: 10px;
}

.b-compare__block-inside-value ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    height:220px;
}

.b-compare__block-inside-value ul li{
    margin-bottom: 8px;
}

.b-compare__block-inside-value ul li span.fa{
    font-size:16px;
    margin-right: 10px;
}

.b-compare__block-inside-value a.btn{
    padding-left: 20px;
}

.b-compare__block-inside-value a.btn span.fa{
    width:25px;
    height:25px;
}

.b-compare__links{
    text-align: center;
    margin-top: 50px;
}

.b-compare__links a.btn.m-btn{
    font: 600 11px 'Open Sans',sans-serif;
    border:1px solid #dddddd;
    padding-left: 20px;
}

.b-compare__links a.btn.m-btn span.fa{
    width:25px;
    height:25px;
    font-size:14px;
    padding:5px;
    margin-left: 15px;
}