
/* Color one */

.b-search__main-form-submit button span.fa,
.m-home .b-count__item-circle:hover,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.navbar-toggle,
.b-slider__info p span,
.b-slider__info a.btn:hover,
.b-search__main-title,
.b-search__main-form-submit button:hover,
.b-search__main-form-submit button.btn.m-btn:hover span,
.b-search__main-form-submit button span.fa,
.b-featured__item-price,
.b-welcome,
.b-welcome__services-img:hover,
.b-world__item-val-circles span,
.b-world__item a:hover,
.b-asks__first:hover .b-asks__first-circle,
.b-auto__main-item-info .m-price,
.b-auto__main-item-info .m-price,
.b-contact-title h5,
.b-contact__form button,
.b-info__aside > a:hover,
.m-home .b-slider__info .btn.m-btn,
.m-home .b-world__item-num,
.m-home .b-count__item-circle:hover,
.m-home .b-footer__toTop-inside,
.b-pageHeader__search,
.b-items__aside-main-body-item .ui-widget-header,
.b-items__aside-main-footer button,
.b-items__aside-main-footer button:hover span,
.b-items__aside-sell-info a:hover span,
.b-items__cars-one-info-header > span,
.b-items__cars-one-info a.btn:hover,
.b-items__cell-info a.btn:hover,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-price-num,
.b-detail__main-aside-about-seller,
.b-detail__main-aside form button.btn.m-btn,
.b-detail__main-aside form button.btn.m-btn:hover span.fa,
.b-detail__main-info-characteristics-one:hover .b-detail__main-info-characteristics-one-top > div,
.b-compare__links a.btn.m-btn:hover,
.b-compare__images-item-price,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.b-blog__posts-one-body-main-link,
.b-article__main-author,
.b-article__main-add button.btn.m-btn,
.b-article__main-add button.btn.m-btn:hover span.fa,
.s-form button[type='submit'],
.s-form button[type='submit']:hover span.fa,
.b-infoBar__progress-line-step-circle-inner.m-active,
.b-submit__aside-step-inner.m-active,
.s-submit button.btn.m-btn,
.s-submit button.btn.m-btn:hover span.fa,
.b-submit__main-file label.btn.m-btn span.fa,
.b-submit__main-contacts-price-input-usd,
.b-submit__main-contacts-price-plan > a,
.b-submit__main-plan label.s-submitCheckLabel span.m-circle,
a.btn.m-btn.m-pay:hover,
.b-search__main-form-submit button.btn.m-btn span.fa,
.b-info__aside > a.btn.m-btn:hover,
.b-search__main-type svg:hover g,
.ui-slider-handle:after,
.b-asks .b-asks__first:hover,
.b-count,
.b-features,
.b-search__main-type input[type='radio']:checked + label.b-search__main-type-svg svg g,
.b-search__main-form-submit button.btn.m-btn:hover,
.m-home .b-homeAuto__world > a:hover,
.m-home .b-homeReviews__main-body:after,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__aside-main-footer button.btn.m-btn:hover span,
.b-items__aside-sell-info a.btn.m-btn:hover span,
.b-items__cell-info a.btn.m-btn:hover,
.b-compare .b-compare__images .b-compare__images-item-price,
.b-compare__links a.btn.m-btn span.fa,
.b-items__aside-sell-info a.btn.m-btn:hover span.fa,
.b-welcome .container,
.m-index .b-count .m-main,
.demo_changer .demo-icon
{
    background-color:#faa61a;
    fill: #faa61a;
}


.b-nav__logo h3 a,
.b-search__main-form-submit a,
.b-auto__main-toggle:hover,
.b-auto__main .b-auto__main-toggle.m-active,
.b-auto__main-nav li:hover a,
.b-auto__main-nav li.active a,
.b-review__main h5 em,
.b-info__latest > h3,.b-info__aside-article > h3,.b-info__twitter > h3,.b-info__contacts > p,
.m-home .b-search__main >h4,
.m-home .b-count__item h2,
.m-home .b-homeReviews__main-person-name em,
.b-infoBar__compare-item span.fa,
.b-infoBar__select-one a.m-active,.b-infoBar__select-one a:hover,
.b-items__aside-main-body-item > div select +  span.fa,
.b-items__cars-one-img-check span,
.m-listingsTwo .b-items__cars-one-info-price h4,
.b-items__cell-info-price,
.b-detail__main-aside-about-form-links a.m-active,
.b-detail__main-aside-about-form-links a:hover,
.b-detail__main-info-extra ul li span.fa,
.m-compare .b-infoBar h5 span,
.b-compare__block-inside-value ul li span.fa,
.b-blog__aside-search button,
.b-blog__aside-categories-list li:hover a,.b-blog__aside-categories-list li.m-active a,
.b-blog__aside-popular-posts-one-date span.fa,
.b-blog__posts-one-social a:hover,
.m-blogTwo .b-blog__posts-one-body-head-notes-note span.fa,
.b-blog__posts-one-body-main > p a,
.s-list span.fa,
.b-blog__posts-one-body-tags span.fa,
.b-article__main-author-social > a span.fa,
.b-article__main-comments-one-text-head span.fa-long-arrow-left,
.b-personal__worker-img-social-main a:hover,
.b-contacts__address-info-main-item span.fa,
.b-error h2,
.s-submit label span,
.b-submit__main-contacts-check .s-submitCheckLabel span.fa,
.b-submit__main-plan-money-num,
.b-topBar__addr span.fa,.b-topBar__tel span.fa,
.b-topBar__lang a span.fa,
.b-nav__list ul li a span.fa,
.m-home .b-nav__logo h3 a span,
.b-items__pagination-main > span.m-active a,
.b-items__pagination-main > span:hover a
{
    color:#faa61a;
    fill:#faa61a;
}

.b-nav__list ul li a:hover,
.b-slider__info h3,
.s-title,
.b-world__item h2,
.b-auto__main-nav li.active a,
.b-auto__main-nav li:hover a,
.b-info__aside > a.btn.m-btn:hover,
.b-info__aside,
.m-home .b-homeAuto__world-item-info > h2:before,
.m-home .b-world__item-num,
.m-home .b-homeAuto__world > a:hover,
.b-items__cars-one-info-header h2,
.b-items__cars-one-info a.btn:hover,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__cell-info a.btn.m-btn:hover,
.b-items__cell-info h2,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-title,
.s-titleDet,
.b-blog__aside-categories-list li.m-active a,
.b-blog__aside-categories-list li:hover a,
.btn.m-btn.m-readMore:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.b-compare__links a.btn.m-btn:hover,
.b-best__info-head h2,
.s-headerSubmit h2,
.b-submit__aside-step-inner-info h4,
.b-auto__main-nav li:hover:before,
.b-auto__main-nav li.active:before,
.b-welcome__services-circle
{
    border-color:#faa61a;
}

.b-submit__aside-step-inner-info-triangle
{
    border-left-color:#faa61a;
}

.b-compare__block-title > a,.b-more__info-block-title a
{
    border-bottom-color:#faa61a;
}

/* Color two */
body,
.navbar-toggle span,
.btn.m-btn,
.b-slider__info a.btn:hover span,
.b-search__main,
.b-search__main-form-submit button.btn.m-btn:hover span,
.b-featured,
.b-welcome__services-circle,
.b-world,
.b-world__item a:hover span,
.b-asks__first-arrow span,
.b-auto,
.b-contact__form button:hover,
.b-review,
.b-info__aside > a:hover span,
.m-home .b-slider__info .btn.m-btn span.fa,
.m-home .b-slider__info a.btn:hover,
.m-home .b-featured__item > a,
.m-home  .b-homeAuto,
.m-home .b-review__main-person,
.m-home .b-asks,
.m-home .b-partners,
.m-home .b-footer__toTop:hover .b-footer__toTop-inside,
.b-breadCumbs,
.b-infoBar__select-one a,
.b-infoBar__select-one select,
.b-items,
.b-items__aside-main-body-item .ui-slider-handle,
.b-items__aside-main-body-item .slider,
.b-items__aside-main-footer button span.fa,
.b-items__aside-main-footer button:hover,
.b-items__aside-sell-info a span.fa,
.b-items__aside-sell-info a:hover,
.b-items__cars-one-img-check,
.b-items__cars-one-info a.btn:hover span,
.b-items__cell-info a.btn:hover span.fa,
.btn.m-btn.m-infoBtn:hover span.fa,
.b-detail__main-aside form input[type='text'],.b-detail__main-aside form input[type='email'],.b-detail__main-aside form input[type='tel'],.b-detail__main-aside select,
.b-detail__main-aside form button.btn.m-btn:hover,
.b-detail__main-aside form button.btn.m-btn span.fa,
.b-brands,
.b-compare,
.b-compare__links a.btn.m-btn:hover span.fa,
.b-blog,
.btn.m-btn.m-readMore:hover span.fa,
.b-article__main-add button.btn.m-btn span.fa,
.b-best,
.b-what,
.b-more__info-block-title,
.b-more__info-block-inside,
.b-personal,
.b-contacts,
.s-form select,
.s-form input[type='text'],
.s-form textarea,
.s-form button[type='submit'] span.fa,
.b-infoBar__progress-line,
.b-infoBar__progress-line-step-circle,
.b-submit,
.b-submit__main-contacts-inputSelect select,
.b-welcome__services-auto, .b-welcome__services-trade, .b-welcome__services-buying, .b-welcome__services-support,
.b-info__aside > a.btn.m-btn:hover span,
.b-world__item a.btn.m-btn:hover span,
.dropdown-menu,
.b-items__cars-one-info a.btn.m-btn:hover span,
.b-items__aside-main-footer button.btn.m-btn span.fa,
.b-items__aside-main-footer button.btn.m-btn:hover,
.b-items__aside-sell-info a.btn.m-btn span.fa,
.b-items__aside-sell-info a.btn.m-btn:hover,
.b-items__cell-info a.btn.m-btn:hover span.fa,
.b-detail__main-aside form textarea,
.b-article__main-add input[type='text'],
.b-article__main-add textarea,
.b-world .container
{
    background-color: #fff;
    fill:#fff;
}


.b-slider__info h3,.b-slider__info h2,.b-slider__info p,
.btn.m-btn span.fa,
.b-slider__info a.btn:hover,
.b-search__main-title h2,
.b-search__main-form-submit button,
.b-search__main-form-submit button:hover,
.b-search__main-form-submit button span.fa,
.b-featured__item .m-premium,
.b-featured__item-price,
.b-featured__item .m-leasing,
.owl-buttons > div,
.b-welcome__text,
.b-welcome__services-img:hover span.fa,.b-welcome__services-img:hover svg g,
.b-world h6,
.b-world__item a:hover,
.b-asks__first-circle span,
.b-asks__first-info,
.s-titleBg,
.b-auto__main-item-info .m-price,
.b-count,
.b-contact,
.b-contact__form button,
.b-features,
.b-info__aside-article-item h6,
.b-info__aside > a:hover,
.b-info__latest-article-info h6 a,
.m-home .b-topBar,
.m-home .b-topBar__addr,.m-home .b-topBar__tel,
.m-home .b-topBar__nav li a,.m-home .b-topBar__lang > div > a,
.m-home .b-nav__logo h3 a,
.m-home .b-nav__list > #nav > ul > li > a,
.m-home .b-slider__info .btn.m-btn,
.m-home .b-slider__info a.btn:hover span,
.m-home .b-featured .s-title,
.m-home .b-featured__item h5 a,
.m-home .b-world__item-num,
.m-home .b-homeAuto__world > a span.fa,
.m-home .b-count__item-circle:hover,
.m-home .b-count__item-circle:hover span,
.m-home .b-homeReviews,
.m-home .b-homeReviews h1.s-title,
.m-home .b-footer__toTop-inside,
.b-pageHeader,
.b-items__aside-main-body,
.b-items__aside-main-body-item .ui-slider-handle span.min,.b-items__aside-main-body-item .ui-slider-handle span.max,
.b-items__aside-main-footer button,
.b-items__aside-main-footer button:hover span,
.b-items__aside-sell-img h3,
.b-items__aside-sell-info,
.b-items__aside-sell-info a,
.b-items__aside-sell-info a:hover span,
.b-items__cars-one-img-video,.b-items__cars-one-img-video:hover,.b-items__cars-one-img-video:visited,.b-items__cars-one-img-video:active,
.b-items__cars-one-img-type,
.b-items__cars-one-info-header > span,
.b-items__cars-one-info a.btn:hover,
.b-items__pagination-main > a,
.b-items__cell-info a.btn:hover,
.b-infoBar__premium,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-price-num,
.b-detail__main-aside-about-call,
.b-detail__main-aside-about-seller,
.b-detail__main-aside form button.btn.m-btn,
.b-detail__main-aside form button.btn.m-btn:hover span.fa,
.b-detail__main-info-characteristics-one-top > div,
.b-compare__images-item-price,
.b-compare__images-item-price-vs,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.b-blog__posts-one-body-main-link a,
.b-article__main-author h3,
.b-article .b-article__main .b-article__main-author p,
.b-article__main-add button.btn.m-btn,
.b-article__main-add button.btn.m-btn:hover,
.b-article__main-add button.btn.m-btn:hover span.fa,
.b-more__info-block-title a,
.b-more__info-block-title.m-active,
.s-form button[type='submit'],
.s-form button[type='submit']:hover span.fa,
.b-submit__aside-step h3,
.b-submit__aside-step-inner-icon span.fa,
.b-submit__aside-step-inner-info h4,
.s-submit button.btn.m-btn,
.b-submit__main-file label.btn.m-btn span.fa,
.b-submit__main-contacts-price h6,
.b-submit__main-contacts-price-input-usd,
.b-submit__main-contacts-price-note,
.b-submit__main-contacts-price-plan,
.b-submit__main-contacts-price-plan > a,
a.btn.m-btn.m-pay:hover,
.b-search__main-form-submit button.btn.m-btn,
.b-search__main-form-submit button.btn.m-btn:hover,
.b-search__main-form-submit button.btn.m-btn span.fa,
.owl-theme .owl-controls .owl-buttons div:before,
.b-info__aside > a.btn.m-btn:hover,
.m-home .b-slider__info a.btn.m-btn:hover span,
.b-items__cars-one-info a.btn.m-btn:hover,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__aside-main-footer button.btn.m-btn:hover span.fa,
.b-items__aside-sell-info a.btn.m-btn,
.b-items__aside-sell-info a.btn.m-btn:hover span,
.b-items__cell-info a.btn.m-btn:hover,
.b-compare .b-compare__images .b-compare__images-item-price,
.b-compare__block-title > a,
.b-items__cars-one-img-video, .b-items__cars-one-img-video:hover, .b-items__cars-one-img-video:visited, .b-items__cars-one-img-video:active,.b-items__cars-one-img-video:focus
{
    color:#fff;
    fill:#fff;
}

.b-search__main-title h2,
.b-welcome__text h2,
.b-count__item-circle,
.b-items__aside-main-footer button.btn.m-btn:hover,
.b-count__item h5,
.b-contact-title h2,
.b-info__latest > h3, .b-info__aside-article > h3, .b-info__twitter > h3, .b-info__contacts > p,
.b-features__items li,
.b-items__aside-sell-img h3,
.b-items__aside-sell-info a,
.b-pageHeader__search h3,
.b-article__main-author h3,
.b-submit__aside-step-inner.m-active .b-submit__aside-step-inner-info h4,
.b-compare__images-item-price-vs
{
    border-color:#fff;
}

.b-more__info-block-title.m-active a{
    border-bottom-color:#fff;
}