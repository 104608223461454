
/* Background 1 */
.b-search__main-form-submit button span.fa,
.m-home .b-count__item-circle:hover,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.navbar-toggle,
.b-slider__info p span,
.b-slider__info a.btn:hover,
.b-search__main-title,
.b-search__main-form-submit button:hover,
.b-search__main-form-submit button.btn.m-btn:hover span,
.b-search__main-form-submit button span.fa,
.b-featured__item-price,
.b-welcome,
.b-welcome__services-img:hover,
.b-world__item-val-circles span,
.b-world__item a:hover,
.b-asks__first:hover .b-asks__first-circle,
.b-auto__main-item-info .m-price,
.b-auto__main-item-info .m-price,
.b-contact-title h5,
.b-contact__form button,
.b-info__aside > a:hover,
.m-home .b-slider__info .btn.m-btn,
.m-home .b-world__item-num,
.m-home .b-count__item-circle:hover,
.m-home .b-footer__toTop-inside,
.b-pageHeader__search,
.b-items__aside-main-body-item .ui-widget-header,
.b-items__aside-main-footer button,
.b-items__aside-main-footer button:hover span,
.b-items__aside-sell-info a:hover span,
.b-items__cars-one-info-header > span,
.b-items__cars-one-info a.btn:hover,
.b-items__cell-info a.btn:hover,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-price-num,
.b-detail__main-aside-about-seller,
.b-detail__main-aside form button.btn.m-btn,
.b-detail__main-aside form button.btn.m-btn:hover span.fa,
.b-detail__main-info-characteristics-one:hover .b-detail__main-info-characteristics-one-top > div,
.b-compare__links a.btn.m-btn:hover,
.b-compare__images-item-price,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.b-blog__posts-one-body-main-link,
.b-article__main-author,
.b-article__main-add button.btn.m-btn,
.b-article__main-add button.btn.m-btn:hover span.fa,
.s-form button[type='submit'],
.s-form button[type='submit']:hover span.fa,
.b-infoBar__progress-line-step-circle-inner.m-active,
.b-submit__aside-step-inner.m-active,
.s-submit button.btn.m-btn,
.s-submit button.btn.m-btn:hover span.fa,
.b-submit__main-file label.btn.m-btn span.fa,
.b-submit__main-contacts-price-input-usd,
.b-submit__main-contacts-price-plan > a,
.b-submit__main-plan label.s-submitCheckLabel span.m-circle,
a.btn.m-btn.m-pay:hover,
.b-search__main-form-submit button.btn.m-btn span.fa,
.b-info__aside > a.btn.m-btn:hover,
.b-search__main-type svg:hover g,
.ui-slider-handle:after,
.b-asks .b-asks__first:hover,
.b-count,
.b-features,
.b-search__main-type input[type='radio']:checked + label.b-search__main-type-svg svg g,
.b-search__main-form-submit button.btn.m-btn:hover,
.m-home .b-homeAuto__world > a:hover,
.m-home .b-homeReviews__main-body:after,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__aside-main-footer button.btn.m-btn:hover span,
.b-items__aside-sell-info a.btn.m-btn:hover span,
.b-items__cell-info a.btn.m-btn:hover,
.b-compare .b-compare__images .b-compare__images-item-price,
.b-compare__links a.btn.m-btn span.fa,
.b-items__aside-sell-info a.btn.m-btn:hover span.fa
{
    background-color:#f76d2b;
    fill: #f76d2b;
}

/* Color 1*/
.b-nav__logo h3 a,
.b-search__main-form-submit a,
.b-auto__main-toggle:hover,
.b-auto__main .b-auto__main-toggle.active,
.b-auto__main-nav li:hover a,
.b-auto__main-nav li.active a,
.b-review__main h5 em,
.b-info__latest > h3,.b-info__aside-article > h3,.b-info__twitter > h3,.b-info__contacts > p,
.m-home .b-search__main >h4,
.m-home .b-count__item h2,
.m-home .b-homeReviews__main-person-name em,
.b-infoBar__compare-item span.fa,
.b-infoBar__select-one a.m-active,.b-infoBar__select-one a:hover,
.b-items__aside-main-body-item > div select +  span.fa,
.b-items__cars-one-img-check span,
.m-listingsTwo .b-items__cars-one-info-price h4,
.b-items__cell-info-price,
.b-detail__main-aside-about-form-links a.m-active,
.b-detail__main-aside-about-form-links a:hover,
.b-detail__main-info-extra ul li span.fa,
.m-compare .b-infoBar h5 span,
.b-compare__block-inside-value ul li span.fa,
.b-blog__aside-search button,
.b-blog__aside-categories-list li:hover a,.b-blog__aside-categories-list li.m-active a,
.b-blog__aside-popular-posts-one-date span.fa,
.b-blog__posts-one-social a:hover,
.m-blogTwo .b-blog__posts-one-body-head-notes-note span.fa,
.b-blog__posts-one-body-main > p a,
.s-list span.fa,
.b-blog__posts-one-body-tags span.fa,
.b-article__main-author-social > a span.fa,
.b-article__main-comments-one-text-head span.fa-long-arrow-left,
.b-personal__worker-img-social-main a:hover,
.b-contacts__address-info-main-item span.fa,
.b-error h2,
.s-submit label span,
.b-submit__main-contacts-check .s-submitCheckLabel span.fa,
.b-submit__main-plan-money-num,
.b-topBar__addr span.fa,.b-topBar__tel span.fa,
.b-topBar__lang a span.fa,
.b-nav__list ul li a span.fa,
.m-home .b-nav__logo h3 a span,
.b-items__pagination-main > span.m-active a,
.b-items__pagination-main > span:hover a
{
    color:#f76d2b;
    fill:#f76d2b;
}

/* Border Color */
.b-nav__list ul li a:hover,
.b-slider__info h3,
.s-title,
.b-world__item h2,
.b-auto__main-nav li.active a,
.b-auto__main-nav li:hover a,
.b-info__aside > a.btn.m-btn:hover,
.b-info__aside,
.m-home .b-homeAuto__world-item-info > h2:before,
.m-home .b-world__item-num,
.m-home .b-homeAuto__world > a:hover,
.b-items__cars-one-info-header h2,
.b-items__cars-one-info a.btn:hover,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__cell-info a.btn.m-btn:hover,
.b-items__cell-info h2,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-title,
.s-titleDet,
.b-blog__aside-categories-list li.m-active a,
.b-blog__aside-categories-list li:hover a,
.btn.m-btn.m-readMore:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.b-compare__links a.btn.m-btn:hover,
.b-best__info-head h2,
.s-headerSubmit h2,
.b-submit__aside-step-inner-info h4,
.b-auto__main-nav li:hover:before,
.b-auto__main-nav li.active:before
{
    border-color:#f76d2b;
}


body,
.navbar-toggle span,
.btn.m-btn,
.b-slider__info a.btn:hover span,
.b-search__main,
.b-search__main-form-submit button.btn.m-btn:hover span,
.b-featured,
.b-welcome__services-circle,
.b-world,
.b-world__item a:hover span,
.b-asks__first-arrow span,
.b-auto,
.b-contact__form button:hover,
.b-review,
.b-info__aside > a:hover span,
.m-home .b-slider__info .btn.m-btn span.fa,
.m-home .b-slider__info a.btn:hover,
.m-home .b-featured__item > a,
.m-home  .b-homeAuto,
.m-home .b-review__main-person,
.m-home .b-asks,
.m-home .b-partners,
.m-home .b-footer__toTop:hover .b-footer__toTop-inside,
.b-breadCumbs,
.b-infoBar__select-one a,
.b-infoBar__select-one select,
.b-items,
.b-items__aside-main-body-item .ui-slider-handle,
.b-items__aside-main-body-item .slider,
.b-items__aside-main-footer button span.fa,
.b-items__aside-main-footer button:hover,
.b-items__aside-sell-info a span.fa,
.b-items__aside-sell-info a:hover,
.b-items__cars-one-img-check,
.b-items__cars-one-info a.btn:hover span,
.b-items__cell-info a.btn:hover span.fa,
.btn.m-btn.m-infoBtn:hover span.fa,
.b-detail__main-aside form input[type='text'],.b-detail__main-aside form input[type='email'],.b-detail__main-aside form input[type='tel'],.b-detail__main-aside select,
.b-detail__main-aside form button.btn.m-btn:hover,
.b-detail__main-aside form button.btn.m-btn span.fa,
.b-brands,
.b-compare,
.b-compare__links a.btn.m-btn:hover span.fa,
.b-blog,
.btn.m-btn.m-readMore:hover span.fa,
.b-article__main-add button.btn.m-btn span.fa,
.b-best,
.b-what,
.b-more__info-block-title,
.b-more__info-block-inside,
.b-personal,
.b-contacts,
.s-form select,
.s-form input[type='text'],
.s-form textarea,
.s-form button[type='submit'] span.fa,
.b-infoBar__progress-line,
.b-infoBar__progress-line-step-circle,
.b-submit,
.b-submit__main-contacts-inputSelect select,
.b-welcome__services-auto, .b-welcome__services-trade, .b-welcome__services-buying, .b-welcome__services-support,
.b-info__aside > a.btn.m-btn:hover span,
.b-world__item a.btn.m-btn:hover span,
.dropdown-menu,
.b-items__cars-one-info a.btn.m-btn:hover span,
.b-items__aside-main-footer button.btn.m-btn span.fa,
.b-items__aside-main-footer button.btn.m-btn:hover,
.b-items__aside-sell-info a.btn.m-btn span.fa,
.b-items__aside-sell-info a.btn.m-btn:hover,
.b-items__cell-info a.btn.m-btn:hover span.fa,
.b-detail__main-aside form textarea,
.b-article__main-add input[type='text'],
.b-article__main-add textarea
{
    background-color: #fff;
    fill:#fff;
}


.b-slider__info h3,.b-slider__info h2,.b-slider__info p,
.btn.m-btn span.fa,
.b-slider__info a.btn:hover,
.b-search__main-title h2,
.b-search__main-form-submit button,
.b-search__main-form-submit button:hover,
.b-search__main-form-submit button span.fa,
.b-featured__item .m-premium,
.b-featured__item-price,
.b-featured__item .m-leasing,
.owl-buttons > div,
.b-welcome__text,
.b-welcome__services-img:hover span.fa,.b-welcome__services-img:hover svg g,
.b-world h6,
.b-world__item a:hover,
.b-asks__first-circle span,
.b-asks__first-info,
.s-titleBg,
.b-auto__main-item-info .m-price,
.b-count,
.b-contact,
.b-contact__form button,
.b-features,
.b-info__aside-article-item h6,
.b-info__aside > a:hover,
.b-info__latest-article-info h6 a,
.m-home .b-topBar,
.m-home .b-topBar__addr,.m-home .b-topBar__tel,
.m-home .b-topBar__nav li a,.m-home .b-topBar__lang > div > a,
.m-home .b-nav__logo h3 a,
.m-home .b-nav__list > #nav > ul > li > a,
.m-home .b-slider__info .btn.m-btn,
.m-home .b-slider__info a.btn:hover span,
.m-home .b-featured .s-title,
.m-home .b-featured__item h5 a,
.m-home .b-world__item-num,
.m-home .b-homeAuto__world > a span.fa,
.m-home .b-count__item-circle:hover,
.m-home .b-count__item-circle:hover span,
.m-home .b-homeReviews,
.m-home .b-homeReviews h1.s-title,
.m-home .b-footer__toTop-inside,
.b-pageHeader,
.b-items__aside-main-body,
.b-items__aside-main-body-item .ui-slider-handle:before,
.b-items__aside-main-footer button,
.b-items__aside-main-footer button:hover span,
.b-items__aside-sell-img h3,
.b-items__aside-sell-info,
.b-items__aside-sell-info a,
.b-items__aside-sell-info a:hover span,
.b-items__cars-one-img-video,.b-items__cars-one-img-video:hover,.b-items__cars-one-img-video:visited,.b-items__cars-one-img-video:active,
.b-items__cars-one-img-type,
.b-items__cars-one-info-header > span,
.b-items__cars-one-info a.btn:hover,
.b-items__pagination-main > a,
.b-items__cell-info a.btn:hover,
.b-infoBar__premium,
.btn.m-btn.m-infoBtn:hover,
.b-detail__head-price-num,
.b-detail__main-aside-about-call,
.b-detail__main-aside-about-seller,
.b-detail__main-aside form button.btn.m-btn,
.b-detail__main-aside form button.btn.m-btn:hover span.fa,
.b-detail__main-info-characteristics-one-top > div,
.b-compare__images-item-price,
.b-compare__images-item-price-vs,
.b-compare__links a.btn.m-btn:hover,
.b-blog__aside-reviews-posts-one-info .b-world__item-num,
.btn.m-btn.m-readMore:hover,
.b-blog__posts-one-body-main-link a,
.b-article__main-author h3,
.b-article .b-article__main .b-article__main-author p,
.b-article__main-add button.btn.m-btn,
.b-article__main-add button.btn.m-btn:hover,
.b-article__main-add button.btn.m-btn:hover span.fa,
.b-more__info-block-title a,
.b-more__info-block-title.m-active,
.s-form button[type='submit'],
.s-form button[type='submit']:hover span.fa,
.b-submit__aside-step h3,
.b-submit__aside-step-inner-icon span.fa,
.b-submit__aside-step-inner-info h4,
.s-submit button.btn.m-btn,
.b-submit__main-file label.btn.m-btn span.fa,
.b-submit__main-contacts-price h6,
.b-submit__main-contacts-price-input-usd,
.b-submit__main-contacts-price-note,
.b-submit__main-contacts-price-plan,
.b-submit__main-contacts-price-plan > a,
a.btn.m-btn.m-pay:hover,
.b-search__main-form-submit button.btn.m-btn,
.b-search__main-form-submit button.btn.m-btn:hover,
.b-search__main-form-submit button.btn.m-btn span.fa,
.owl-theme .owl-controls .owl-buttons div:before,
.b-info__aside > a.btn.m-btn:hover,
.m-home .b-slider__info a.btn.m-btn:hover span,
.b-items__cars-one-info a.btn.m-btn:hover,
.b-items__aside-main-footer button.btn.m-btn,
.b-items__aside-main-footer button.btn.m-btn:hover span.fa,
.b-items__aside-sell-info a.btn.m-btn,
.b-items__aside-sell-info a.btn.m-btn:hover span,
.b-items__cell-info a.btn.m-btn:hover,
.b-compare .b-compare__images .b-compare__images-item-price,
.b-compare__block-title > a,
.b-items__cars-one-img-video, .b-items__cars-one-img-video:hover, .b-items__cars-one-img-video:visited, .b-items__cars-one-img-video:active,.b-items__cars-one-img-video:focus
{
    color:#fff;
    fill:#fff;
}

.b-search__main-title h2,
.b-welcome__text h2,
.b-count__item-circle,
.b-items__aside-main-footer button.btn.m-btn:hover,
.b-count__item h5,
.b-contact-title h2,
.b-info__latest > h3, .b-info__aside-article > h3, .b-info__twitter > h3, .b-info__contacts > p,
.b-features__items li,
.b-items__aside-sell-img h3,
.b-items__aside-sell-info a,
.b-pageHeader__search h3,
.b-article__main-author h3,
.b-submit__aside-step-inner.m-active .b-submit__aside-step-inner-info h4
{
    border-color:#fff;
}

.btn.m-btn span.fa,
.b-search__main-form-submit button,
.b-world h6,
.b-asks__first,
.b-asks__first.m-second,
.s-titleBg,
.m-home .b-slider__info a.btn:hover span,
.m-home .b-homeAuto__world > a span.fa,
.b-items__aside-main-body,
.b-detail__main-aside-about-call,
.b-detail__main-info-characteristics-one-top > div,
.b-compare__images-item-price-vs,
.b-blog__aside-reviews-posts-one-info .b-world__item-val-circles span,
.b-article__main-add button.btn.m-btn:hover,
.b-more__info-block-title.m-active,
.s-form button[type='submit']:hover,
.s-submit button.btn.m-btn:hover
{
    background-color: #555;
}


.b-topBar__addr,.b-topBar__tel,
.b-topBar__nav li a,.b-topBar__lang a,
.b-nav__list ul li a,
.btn.m-btn,
.m-active h5,
.b-welcome__services-img span.fa,
.b-auto__main-nav li a,
.b-auto__main-nav li.active span.fa,
.b-contact__form form > div span,
.m-home .b-slider__info a.btn:hover,
.m-home .b-search .container > h1,
.m-home .b-homeAuto__world > a,
.m-home .b-count,
.b-infoBar__compare-item span.fa.fa-caret-down,
.b-items__cars-one-info-title,
.btn.m-btn.m-infoBtn:hover span.fa,
select.m-select + span.fa,
.b-detail__main-aside form button.btn.m-btn:hover,
.b-detail__main-aside form button.btn.m-btn span.fa,
.b-detail__main-info-characteristics-one-bottom,
.b-compare__block-inside-title,
.b-compare__links a.btn.m-btn,
.b-compare__links a.btn.m-btn:hover span.fa,
.b-blog__aside-categories-list li a,
.b-blog__aside-popular-posts-one-date,
.btn.m-btn.m-readMore,
.btn.m-btn.m-readMore:hover span.fa,
.b-blog__posts-one-share,
.b-blog__posts-one-social em,
.b-blog__posts-one-body-tags,
.b-article__main-add button.btn.m-btn span.fa,
.b-more__info-block-title,
.b-more__info-block-title.m-active a,
.s-form button[type='submit'] span.fa,
.b-contacts__address-hours-main h5,
.b-contacts__address-info-main-item
{
    color:#555;
}

.s-title,
.b-asks__call,
.b-detail__main-aside-desc-value,
.m-compare .b-infoBar h5,
.b-blog__aside-popular-posts h4 a,
.b-blog__aside-reviews-posts-one-info p,
.b-best__info h6,
.b-contacts__form p,
.b-error p
{
    color: #525252;
}


.b-search,
.b-asks,
.m-home .b-count,
.b-infoBar,
.b-detail__main-aside-about-form,
.b-detail__main-aside-payment-form,
.b-detail__main-info-characteristics-one-bottom,
.b-detail__main-info-characteristics-one:hover,
.b-related,
.b-compare__block-title,
.b-article__main-related-item,
.b-more,
.b-contacts__address-hours-main,
.b-contacts__address-info-main,
.b-submit__main-file label.btn.m-btn,
.m-home .b-count .m-main
{
    background-color: #f5f5f5;
}

.b-world__item p,
.b-review__main p,
.m-home .b-homeAuto__world-item-text p,
.b-items__aside-sell-info a span.fa,
.b-items__aside-sell-info a:hover,
.b-items__cars-one-info p,
.b-items__cell-info > p,
.b-detail__main-aside-desc-title,
.b-detail__main-info-text p,
.b-compare__block-inside-value,
.b-blog__aside-text p,
.b-blog__posts-one-body-main > p,
.b-blog__posts-one-info p,
.s-list li,
.b-blog__posts-one p,
.b-article__main-comments-one-text p,
.b-best__info p,
.b-more__info-block-inside p,
.b-personal__worker p,
.b-error h1,
.b-submit__main-element label.s-submitCheck,
.b-submit__main p,
.b-submit__main-contacts-check span,
.b-submit__main-contacts-check .s-submitCheck
{
    color:#666;
}


.b-nav__logo h2 a,
.b-search__main-form p,
.b-featured__item-count,
.b-featured__item-links a,
.b-world__item-val-title,
.b-world__item-num,
.b-auto__main-toggle,.b-auto__main-toggle:hover,.b-auto__main-toggle:visited,.b-auto__main-toggle:focus,.b-auto__main-toggle:active,
.b-auto__main-item-info .m-number,
.b-review__main h5,
.b-info__aside p,
.b-info__latest-article-info p,
.b-info__twitter-article-content p,
.b-info__contacts,
.b-info__map a,
.b-footer__company p,
.b-footer__content-social a,
.b-footer__content-social a:hover,.b-footer__content-social a:visited,.b-footer__content-social a:active,.b-footer__content-social a:focus,
.b-footer__content-nav ul li a,
.m-home .b-homeAuto__world-item-text span,
.b-breadCumbs__page,.b-breadCumbs__page:hover,.b-breadCumbs__page:focus,.b-breadCumbs__page:active,.b-breadCumbs__page:visited,
.b-infoBar__select-one a,
.b-infoBar__select-one select,
.b-items__aside-main-footer > a,
.b-items__cars-one-info-km,
.b-items__pagination-main > span a,
.m-listingsTwo .b-items__cars-one-info-price h3,
.b-items__cars-one-info-value,
.b-items__cell-info ul li,
.b-items__cell-info-km,
.m-listTableTwo .b-items__cell-info-price span,
.b-detail__head h3,
.b-detail__head-price p,
.b-detail__main-aside-about-form-links a,
.b-detail__main-aside form select,
.b-detail__main-aside form label,
.b-blog__aside-search input[type='text'],
.b-blog__posts-one-social a,
.b-blog__posts-one-body-head-notes,
.b-blog__posts-one-body-tags a,
.b-article__main-comments-one-text-head-date,
.b-article__main-add input[type='text'],
.b-article__main-add textarea,
.b-personal__worker h6,
.b-personal__worker-img-social-main a,
.s-form select,
.s-form input[type='text'],
.s-form textarea,
.b-contacts__address-hours-main p,
.b-contacts__address-info-main-item p,
.b-contacts__address-info-main-item em,
.b-error .b-blog__aside-search span.fa,
.s-submit label,
.b-submit__main-file label.btn.m-btn + label,
.b-submit__main-plan-money-note
{
    color:#999;
}

.b-contact,
.b-info,
.m-home .b-topBar,
.m-home .b-slider__info p span
{
    background-color: #333;
}


.m-home .b-nav,
.b-footer
{
    background-color: #222;
}


.b-nav__logo h3 a span,
.b-search__main-type h4,
.b-breadCumbs__page.m-active,.b-breadCumbs span.fa,
.b-items__cars-one-info a.btn,
.b-items__cars-one-info a.btn:hover span,
.b-items__cell-info a.btn,
.b-items__cell-info a.btn:hover span.fa,
.b-submit__main-file label.btn.m-btn,
.b-submit__main-contacts-select
{
    color:#545454;
}


.b-slider__info a.btn:hover span,
.b-search__main-form-submit button.btn.m-btn:hover span,
.b-world__item a:hover span,
.b-info__aside > a:hover span,
.m-home .b-slider__info .btn.m-btn span.fa
{
    color:#6a6a6a;
}


.b-featured__item h5 a,
.b-world__item h2,
.b-auto__main-item h2 a,
.b-review__main h5 span,
.m-home .b-homeAuto__world-item-info > h2 a,
.b-items__cell-info h2,
.b-items__cell-info h2 a,
.b-detail__head h1,
.b-blog__aside-categories h2,
.b-blog__posts-one-body-head h2,
.b-blog__posts-one-body-main > blockquote,
.b-blog__aside h2,
.m-article .b-blog__posts-one-body h2,
.b-article__main-related-item h5 a,
.b-article__main-comments-one-text-head h6,
.b-best__info-head h2,
.b-more__why p,
.b-personal__worker-name h4.s-titleDet,
.b-contacts__form-header h2,
label.s-submitCheckLabel span.fa,
a.btn.m-btn.m-pay,
.b-auto__main-item h2 a
{
    color:#000;
}

.b-info__aside {
    background-color: #272727;
}
