/*//////////////////////////////////////////////////////////////////////////////////////////*/
/* CUSTOM CSS (ADD YOUR CSS HERE!) */
/*//////////////////////////////////////////////////////////////////////////////////////////*/


.b-review__main-person-inside {
     background: none 
    width: 89px;
    height: 89px;
    border-radius: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.b-info__latest-article-photo.m-audi {
    background:none;
}

rzslider .rz-bubble.rz-limit {
    color: white;
}

rzslider .rz-bubble {
    bottom: 16px;
    padding: 1px 3px;
    color: white;
    cursor: default;
}
